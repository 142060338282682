export const statusList = {
  10: '待支付定金',
  20: '已支付定金',
  30: '已安排生产',
  40: '待支付尾款',
  45: '待支付',
  50: '已支付尾款',
  55: '已支付',
  60: '已发货',
  70: '确认收货',
  80: '售后',
  90: '取消订单'
}

export const typeList = {
  1: '钻戒',
  2: '彩宝',
  3: '对戒',
  4: '钻石'
}

export const sfTypeList = {
  1: '邮寄',
  2: '代发',
  3: '自提',
  4: '送货上门'
}
